import React, {FC, useEffect, useState} from 'react';
import {Select} from 'antd';
import {MRule} from '../../types/MaskingRule/MRule';

export type RuleSelectComponentProps = {
    className?: string;
    onSelect?: () => void;
    maskingRules?: MRule [];
    record?: any;
    val?: any;
};

export const RuleSelectComponent: FC<RuleSelectComponentProps> = ({

                                                                      className,
                                                                      onSelect,
                                                                      maskingRules,
                                                                      record,
                                                                      val
                                                                  }) => {
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(val);
    }, [val]);


    const onChange = (val: any) => {
        setValue(val);
        record.maskingRuleId = val;

    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return (

        <Select style={{width: '100%'}}

                defaultValue={val}
                onChange={(val) => onChange(val)}
                value={value}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                placeholder={'Выберите из списка'}
                filterOption={filterOption}
                options={maskingRules?.map((rule) => ({label: rule.name, value: rule.id || ''}))}
                showSearch/>
    );

}
