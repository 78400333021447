import React, {FC, useEffect, useState} from 'react';
import {Button, ButtonProps, ConfigProvider} from 'antd';

import {DeleteFilled} from '@ant-design/icons';

export type DeleteButtonProps = {


    type?: string;
    btnText?: string;
    disabled: boolean;
    className?: string;
    onClickHandler?: () => void;
};

export const DeleteButton: FC<DeleteButtonProps> = ({

                                                        className,
                                                        btnText,
                                                        type,
                                                        disabled,
                                                        onClickHandler
                                                    }) => {


    const [btnType, setBtnType] = useState<ButtonProps['type']>('default');

    useEffect(() => {
        switch (type) {
            case 'primary':
                setBtnType('primary');
                break;
            case 'link':
                setBtnType('link');
                break;
            case 'text':
                setBtnType('text');
                break;
            case 'dashed':
                setBtnType('dashed');
                break;
            default:
                setBtnType('default');
                break
        }
    });

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>

            <Button
                disabled={disabled}
                icon={<DeleteFilled/>}
                type={btnType}
                onClick={onClickHandler}
                className={className}>
                {btnText}
            </Button>
        </ConfigProvider>
    )
}
