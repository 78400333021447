import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {Provider, useSelector} from 'react-redux';
import React from 'react';
import {Layout} from './hoc/Layout';
import {SignIn} from './pages/SignIn';
import {SignUp} from './pages/SignUp';
import {store} from './redux/store';
import {authValidate} from './redux/selectors';
import {Loading} from './pages/Loading';
import {Error} from './pages/Error';

import {Account} from './pages/Account/Account';
import {Tools} from './pages/Tools/Tools';

import {Tokenization} from './pages/Tokenization/Tokenization';
import {Detokenization} from './pages/Detokenization/Detokenization';
import {Control} from './pages/Control/Control';
import {Templates} from './pages/Templates/Templates';
import {UsersNew} from './pages/Users/UsersNew';
import {User} from './pages/Users/User';

import {Group} from './pages/Groups/Group';
import {Groups} from './pages/Groups/Groups';
import {Dictionary} from './pages/Dictionaries/Dictionary';
import {Dictionaries} from './pages/Dictionaries/Dictionaries';
import {MaskingRule} from './pages/MaskingRules/MaskingRule';
import {MaskingRules} from './pages/MaskingRules/MaskingRules';
import {MonitoringServerAllCharts} from './pages/Monitoring/MonitoringServerAllCharts';
import {Monitoring} from './pages/Monitoring/Monitoring';

function APP() {
    let validate = useSelector(authValidate);


    return (
        <Router>
            {validate === null ? (
                <Loading/>
            ) : validate === false ? (
                <Routes>
                    <Route
                        path="/signin"
                        element={<SignIn/>}
                    />
                    <Route
                        path="*"
                        element={(
                            <Navigate
                                to="/signin"
                                replace
                            />
                        )}
                    />
                </Routes>
            ) : (
                <Layout>
                    <Routes>

                        <Route
                            path="/signin"
                            element={(
                                <Navigate
                                    to="/tools/tokenization"
                                    replace
                                />
                            )}
                        />

                        <Route
                            path="/account"
                            element={<Account/>}
                        />
                        <Route
                            path="/tools"
                            element={<Tools/>}>
                            <Route path="groups" element={<Groups/>}/>
                            <Route path="tokenization" element={<Tokenization/>}/>
                            <Route path="detokenization" element={<Detokenization/>}/>
                        </Route>
                        <Route path="/control" element={<Control/>}>
                            <Route path="groups" element={<Groups/>}>
                                <Route path="create" element={<Group/>}/>
                                <Route path="edit" element={<Group/>}>
                                    <Route path=":id"/>
                                </Route>
                            </Route>
                            <Route path="templates" element={<Templates/>}/>
                            <Route path="users" element={<UsersNew/>}>
                                <Route path="create" element={<User/>}/>
                                <Route path="edit" element={<User/>}>
                                    <Route path=":id"/>
                                </Route>
                            </Route>
                            <Route path="dictionaries" element={<Dictionaries/>}>
                                <Route path="create" element={<Dictionary/>}/>
                                <Route path="edit" element={<Dictionary/>}>
                                    <Route path=":id"/>
                                </Route>
                            </Route>
                            <Route path="rules" element={<MaskingRules/>}>
                                <Route path="create" element={<MaskingRule/>}/>
                                <Route path="edit" element={<MaskingRule/>}>
                                    <Route path=":id"/>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="/monitoring" element={<Monitoring/>}>
                            <Route path="server" element={<MonitoringServerAllCharts/>}>
                                <Route path=":id/:name"/>
                            </Route>
                        </Route>
                        <Route
                            path="/error"
                            element={<Error/>}
                        />
                        <Route
                            path="/signup"
                            element={<SignUp/>}
                        />
                        <Route
                            path="*"
                            element={(
                                <Navigate
                                    to="/error"
                                    replace
                                />
                            )}
                        />
                    </Routes>
                </Layout>
            )}
        </Router>
    );
}

function App() {
    return (
        <Provider store={store}>
            <APP/>
        </Provider>
    );
}

export default App;
