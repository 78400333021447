import styles from './UsersNew.module.scss';
import * as React from 'react';
import {ChangeEvent, useEffect, useState} from 'react';

import {createUser, deleteUser, getUser, unlockUser, updateUser} from '../../services/pages/users';
import {doNavigate, i18n} from '../../utils';
import {useNavigate} from 'react-router-dom';
import {TUser} from '../../types/User';
import {Button, Card, ConfigProvider, Input, Select, Tooltip} from 'antd';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {useParams} from 'react-router';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {DeleteButton} from '../../components/DeleteButton/DeleteButton';
import {DeleteModal} from '../../components/DeleteModal/DeleteModal';
import {LockFilled} from '@ant-design/icons';

const {TextArea} = Input;


export function User() {

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);

    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});

    const roles = [{label: 'user', value: 'user'}, {label: 'admin', value: 'admin'}];

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = () => {
        if (id) {
            getUser(id || '').then((response) => {
                    setUser(response);
                }
            ).catch((err) => console.log(err))
        }
    }

    const [user, setUser] = useState<TUser>({
        id: "",
        username: "",
        display_name: "",
        description: "",
        enabled: true,
        email: "",
        password: ""

    });

    const create = () => {
        setErrors(validateValues(user));
        setSubmitting(true);
    }

    const validateValues = (user: TUser | undefined) => {

        console.log('VALIDATE_START');
        console.log(user);
        let errors = {
            username: "",
            password: "",
            display_name: "",
            email: ""
        };
        if (!user?.username) {
            errors.username = "Необходимо заполнить поле";
        }
        if (!user?.password && !id) {
            errors.password = "Необходимо заполнить поле";
        }
        if (!user?.display_name) {
            errors.display_name = "Необходимо заполнить поле";
        }
        if (!user?.email) {
            errors.email = "Необходимо заполнить поле";
        }

        console.log('VALIDATE_ERRORS');
        console.log(errors);
        return errors;
    };

    const [errors, setErrors] = useState({
        username: "",
        password: "",
        display_name: "",
        email: ""
    });

    useEffect(() => {
        if (!hasErrors() && submitting) {
            finishSubmit();
        }
    }, [errors]);

    const hasErrors = () => {
        let res = false;
        if (errors.username) res = true;
        if (errors.display_name) res = true;
        if (errors.email) res = true;
        if (errors.password) res = true;
        return res;
    }

    const finishSubmit = () => {
        saveUser(user!)
    }

    const saveUser = (user: TUser) => {
        if (id) {
            delete user.id;
            updateUser(id || '', user).then((res) => {

                handleResponse(res, 'update');

            }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('update_user_error') + error,
                    show: true
                });
            });
        } else {
            createUser(user)
                .then((res) => {
                    handleResponse(res, 'create');
                }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('create_user_error') + error,
                    show: true
                })
            });
        }
    }

    const onDeleteUser = (user: TUser) => {

        deleteUser(user.id || '').then((res) => {
            doNavigate(`/control/users`, navigate);
        }).catch((error) => {
            setAntAlertProps({
                severity: 'error',
                content: i18n('create_user_error') + error,
                show: true
            })
        });
    }

    const cancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        doNavigate(`/control/users`, navigate);
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLTextAreaElement>) => {
        setUser((prev)=> ({...prev, [e.target.name]: e.target.value}))
    }

    const handleResponse = (res: any, action: string) => {

        if (res.errors != undefined && res.errors.length > 0) {
            setAntAlertProps({
                severity: 'error',
                content: i18n(action + '_user_error') + user?.username + ' ' + res.errors[0].message,
                show: true
            });
        } else {
            setAntAlertProps({
                severity: 'success',
                content: i18n(action + '_user_success') + user?.username + ' ',
                show: true
            });
        }
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }

    const onDeleteButtonClick = () => {
        setIsDeleteModalOpen(true);
    }

    const onUnlockButtonClick = () => {

        unlockUser(id || '').then((res) => {
            if(res.success=== true) {
                getUserData();
                setAntAlertProps({
                    severity: 'success',
                    content: i18n( 'unlock_user_success') + user?.username + ' ',
                    show: true
                });
            } else {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n( 'unlock_user_error') + user?.username + ' ',
                    show: true
                });
            }
        });
    }

    const onDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    }

    const onDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        onDeleteUser(user!);
    }

    const onRoleChange=(roles: string[]) => {
        setUser((prev)=> ({...prev, roles: roles}))
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',

                        algorithm: true,
                    },
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'user'}>
                <div id="user_page" className={styles.page}>

                    <div className="header_1">
                        {id ? 'Профиль пользователя' : 'Добавление пользователя'}

                        <div className={styles.float_right} id="unlocked">


                        <span style={{marginRight: '10px'}} hidden={!user.locked}>
                            <Tooltip title={'Заблокирован дo ' +  user.lockout_end }
                                     getPopupContainer={() => document.getElementById('unlocked')!}>
                            <Button
                                icon={<LockFilled />}
                                type={'default'}
                                onClick={onUnlockButtonClick}/>
                            </Tooltip>
                        </span>

                         <span style={{marginRight:'10px'}}>
                        <DeleteButton onClickHandler={onDeleteButtonClick} disabled={!id}/>
                         </span>
                        <ActionButton btnText={'Сохранить'}
                                      htmlType={'submit'}
                                      type={'primary'}
                                      onClickHandler={create}
                                      />
                        </div>
                    </div>

                    <div className={styles.form}>

                        <div className={styles.form_left}>

                            <div>
                                <div style={{marginBottom: '4px'}}>
                                    Имя
                                    <span className={styles.required}> * </span>
                                </div>

                                <Input name="username"
                                       value={user?.username}
                                       onChange={(e) => onInputChange(e)}/>
                                {errors.username ? (
                                    <p className={styles.error}> {errors.username} </p>
                                ) : null}
                            </div>

                            <div>
                                <div style={{marginTop: '24px', marginBottom: '4px'}}>
                                    Отображаемое имя
                                    <span className={styles.required}> * </span>
                                </div>

                                <Input name="display_name"
                                       value={user?.display_name}
                                       onChange={(e) => onInputChange(e)}/>
                                {errors.display_name ? (
                                    <p className={styles.error}> {errors.display_name} </p>
                                ) : null}
                            </div>

                            <div>
                                <div style={{marginTop: '24px', marginBottom: '4px'}}>
                                    Email
                                    <span className={styles.required}> * </span>
                                </div>

                                <Input name="email"
                                       value={user?.email}
                                       onChange={(e) => onInputChange(e)}/>
                                {errors.email ? (
                                    <p className={styles.error}> {errors.email} </p>
                                ) : null}
                            </div>

                            <div>
                                <div style={{marginTop: '24px', marginBottom: '4px'}}>
                                    Пароль
                                    <span className={styles.required}> * </span>
                                </div>

                                <Input name="password"
                                       value={user?.password}
                                       onChange={(e) => onInputChange(e)}/>
                                {errors.password ? (
                                    <p className={styles.error}> {errors.password} </p>
                                ) : null}
                            </div>

                        </div>

                        <div className={styles.form_right}>

                            <div style={{marginBottom: '4px'}}>
                                Описание

                            </div>
                            <TextArea style={{height: "260px"}}
                                      value={user?.description || ''}
                                      name="description"
                                      spellCheck={false}
                                      onChange={(e) => onInputChange(e)}/>
                        </div>

                    </div>

                    <div style={{marginTop: '24px'}}>

                        <div style={{marginBottom: '4px'}}>
                            Роли

                        </div>


                        <Select style={{width: '100%'}}
                                mode='tags'
                                value={user.roles}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                placeholder={'Выберите значение'}
                                onChange={(val) => onRoleChange(val)}
                                options={roles}
                        />

                    </div>


                    <div className={styles.notification}>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>

                <DeleteModal title={'Удаление профиля пользователя'}
                             isModalOpen={isDeleteModalOpen}
                             popupContainer={'user_page'}
                             content={'Подтвердить удаление пользователя ' + user?.username}
                             onConfirmHandler={()=>onDeleteConfirm()}
                             onCancelHandler={()=>onDeleteCancel()}/>
            </Card>
        </ConfigProvider>
    );
}
