import {fetchWithRefresh} from '../auth';
import {optionsGet, optionsPost, URL} from '../requst_templates';
import {handleHttpResponse} from '../../utils';

export const getDictionaries = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/dictionary`,
    optionsGet(),
).then(handleHttpResponse);

export const getDictionariesPageable = async (limit: number, offset: number) => fetchWithRefresh(
    `${URL}/v1/dictionary?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`,
    optionsGet(),
).then(handleHttpResponse);

export const createDictionary = async (data: any) => fetchWithRefresh(`${URL}/v1/dictionary`, optionsPost(data)).then(handleHttpResponse);
