import React, {FC, useEffect, useState} from 'react';
import {Button, ButtonProps, ConfigProvider} from 'antd';


export type ActionButtonProps = {
    btnText?: string;
    type?: string;
    htmlType?: string;
    className?: string;
    onClickHandler?: () => void;
};

export const ActionButton: FC<ActionButtonProps> = ({
                                                        btnText,
                                                        type,
                                                        htmlType,
                                                        className,
                                                        onClickHandler
                                                    }) => {


    const [btnType, setBtnType] = useState<ButtonProps['type']>('primary');
    const [btnHtmlType, setBtnHtmlType] = useState<ButtonProps['htmlType']>('button');


    useEffect(() => {
        switch (type) {
            case 'primary':
                setBtnType('primary');
                break;
            case 'link':
                setBtnType('link');
                break;
            case 'text':
                setBtnType('text');
                break;
            case 'dashed':
                setBtnType('dashed');
                break;
            default:
                setBtnType('default');
                break
        }

        switch (htmlType) {
            case 'submit':
                setBtnHtmlType('submit');
                break;
            case 'reset':
                setBtnHtmlType('reset');
                break;

            default:
                setBtnHtmlType('button');
                break
        }
    });


    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        textHoverBg: '#E5E7EB',
                        colorText:'#D53D0D'
                    }
                },
            }}>

            <Button
                onClick={onClickHandler}
                className={className}
                htmlType={btnHtmlType}
                type={btnType}>
                {btnText}
            </Button>
        </ConfigProvider>
    )
}
