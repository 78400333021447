import styles from './Groups.module.scss';
import * as React from "react";
import {useEffect, useState} from "react";

import {createTokenGroup, getTokenGroupWithPermissions, updateTokenGroup} from '../../services/pages/token_group';
import {i18n} from "../../utils";
import {TGroup} from '../../types/GroupToken/TGroup';
import {Template} from '../../types/Template';
import {getTemplates} from '../../services/pages/templates';
import {getUsers} from '../../services/pages/users';
import {UsersResponse} from '../../types/UsersResponse';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {Card, Collapse, CollapseProps, ConfigProvider} from 'antd';
import {CommonGroupInfo} from './CommonGroupInfo';
import {CollapseLabel} from '../../components/CollapseLabel/CollapseLabel';
import {GroupUserPermission} from './GroupUserPermission';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {useParams,} from 'react-router';
import {MRuleResponse} from '../../types/MaskingRule/MRuleResponse';
import {getMaskingRules} from '../../services/pages/masking_rule';
import {TGroupUserToRule} from '../../types/GroupToken/TGroupUserToRule';


export function Group() {

    const [templates, setTemplates] = useState<Template[] | undefined>(undefined);
    const [users, setUsersData] = useState<UsersResponse['items'] | undefined>(undefined);
    const [rules, setRulesData] = useState<MRuleResponse['items'] | undefined>(undefined);
    const [isValidate, setIsValidate] = useState<boolean>(false);
    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});

    const [validateResults, setValidateResults] = useState({
        hasCommonError: true,
        hasPermissionError: true,
    });

    const {id} = useParams();


    useEffect(() => {
        getTemplatesData();
        getAllUsers();
        getGroupData();
        getMaskingRulesData();
    }, []);


    const getTemplatesData = () => {
        getTemplates()
            .then((response) => {
                    setTemplates(response);
                }
            ).catch((err) => console.log(err))
    }


    const getAllUsers = () => {
        getUsers().then((resp) => {
            setUsersData(resp['items']);
        });
    }

    const getGroupData = () => {
        if (id) {
            getTokenGroupWithPermissions(id || '').then((response) => {
                    console.log(response)
                    setTGroup(response);

                }
            ).catch((err) => console.log(err));
        }
    }

    const getMaskingRulesData = () => {
        getMaskingRules().then((resp) => {
            setRulesData([...resp['items']]);
        });
    }


    const [tGroup, setTGroup] = useState<TGroup>({
        id: "",
        name: "",
        description: "",
        default_template_id: ""
    });

    const handleChange = (e: any) => {
        const value = e.target.value;
        setTGroup({
            ...tGroup, [e.target.name]: value,
        });
    }

    const handleTemplateChange = (val: string) => {
        setTGroup({
            ...tGroup, ['default_template_id']: val,
        });
    }


    useEffect(() => {
        console.log("USE_EFFECT_FINISH_VALIDATE")
        console.log(validateResults.hasCommonError);
        console.log(validateResults.hasPermissionError);


        if (!validateResults.hasCommonError && !validateResults.hasPermissionError) {
            console.log("FINISH_SUBMIT")
            finishSubmit();
        }
    }, [validateResults]);


    const finishSubmit = () => {
        saveGroup(tGroup);
    }

    const saveGroup = (tGroup: TGroup) => {
        if (id) {
            delete tGroup.id;
            updateTokenGroup(id || '', tGroup).then((res) => {

                handleResponse(res, 'update');

            }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('update_group_error') + error,
                    show: true
                });
            });
        } else {
            createTokenGroup(tGroup)
                .then((res) => {


                    handleResponse(res, 'create');

                }).catch((error) => {

                setAntAlertProps({
                    severity: 'error',
                    content: i18n('create_group_error') + error,
                    show: true
                })
            });
        }

    }

    const handleResponse = (res: any, action: string) => {

        if (res.errors != undefined && res.errors.length > 0) {
            setAntAlertProps({
                severity: 'error',
                content: i18n(action + '_group_error') + tGroup.name + ' ' + res.errors[0].message,
                show: true
            });
        } else {
            setAntAlertProps({
                severity: 'success',
                content: i18n(action + '_group_success') + tGroup.name + ' ',
                show: true
            });
        }
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }


    const setPermissionHandler = (tokenize: string[], detokenize: string[], search: string[], detokenizeWithRules: TGroupUserToRule[] ) => {

        Object.assign(tGroup, {tokenize_permitted_users: tokenize});
        Object.assign(tGroup, {detokenize_permitted_users: detokenize});
        Object.assign(tGroup, {search_permitted_users: search});
        Object.assign(tGroup, {detokenize_permitted_users_with_masking_rules: detokenizeWithRules});

        console.log("TGROUP_AFTER_SET_PERMISSION");
        console.log(tGroup);
    }


    const createGroup = () => {
        console.log('START_SAVE_GROUP');
        setIsValidate(true);


    }

    const onCommonValidateHandler = (hasError: boolean) => {
        console.log("VALIDATE_FINISH->COMMON_PART")
        console.log('COMMON_PART: ' + hasError);

        setIsValidate(false);

        setValidateResults((pre) => {
            return {...pre, hasCommonError: hasError}
        })

    }

    const onPermissionValidateHandler = (hasError: boolean) => {

        console.log("VALIDATE_FINISH->PERMISSION_PART");
        console.log('PERMISSION_PART: ' + hasError);

        setIsValidate(false);

        setValidateResults((pre) => {
            return {...pre, hasPermissionError: hasError}
        })


    }


    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <CollapseLabel text={'Общая информация'}/>,
            children: <CommonGroupInfo templates={templates}
                                       onValidateFinishHandler={onCommonValidateHandler}
                                       isValidate={isValidate}
                                       tGroup={tGroup}
                                       onInputChange={handleChange}
                                       onSelectChange={handleTemplateChange}/>,
        },
        {
            key: '2',
            label: <CollapseLabel text={'Права пользователей'}/>,
            children: <GroupUserPermission users={users}
                                           maskingRules={rules}
                                           onValidateFinishHandler={onPermissionValidateHandler}
                                           isValidate={isValidate}
                                           tGroup={tGroup}
                                           onSetPermissionHandler={setPermissionHandler}/>,
        }
    ];


    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        borderRadiusLG: 0,

                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'group'}>
                <div id="group_page" className={styles.page}>

                    <div className="header_1">
                        Добавить группу токенизации
                        <ActionButton btnText={'Сохранить'}
                                      htmlType={'submit'}
                                      type={'primary'}
                                      onClickHandler={createGroup}
                                      className={styles.float_right}/>
                    </div>

                    <Collapse items={items}
                              defaultActiveKey={['1']}
                              bordered={false}
                              ghost={true}/>

                    <div className={styles.form}>

                    </div>

                    <div className={styles.notification}>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>
            </Card>
        </ConfigProvider>
    );
}
